<template>
  <div>
    <b-button v-if="!btnAddProductStatus" variant="outline-success" size="sm" @click="checkEditable('SET_EDITABLE_PRODUCTS')">{{ $t('edit') }}</b-button>
    <b-button v-else variant="success" size="sm" @click="updateProducts">{{ $t('save') }}</b-button>
    <b-card
      v-for="(product, index) in orderData.products"
      :key="index"
      class="mt-3"
      border-variant="info"
    >
      <template v-slot:header>
        <b-badge variant="success">{{ product.status }}</b-badge
        >&nbsp;
        <b-button
          variant="danger"
          size="sm"
          @click="removeProduct(product.id)"
          :disabled="!product.can_change">{{ $t("remove") }}</b-button>
      </template>
      <b-row>
        <b-col sm="4">
          <div class="form-group">
            <label for="payment_status">{{ $t("group") }}</label>
            <select v-model="product.group_id" class="form-control" :disabled="!product.can_change">
              <optgroup
                v-for="group in groups"
                :key="group.id"
                :label="group.name"
              >
                <option
                  v-for="child in group.children"
                  :key="child.id"
                  :value="child.id"
                >
                  {{ child.name }}
                </option>
              </optgroup>
            </select>
          </div>
          <div class="form-group" v-if="product.new">
            <label for="payment_status">{{ $t("product") }}</label>
            <model-list-select
              v-model="product.product_id"
              :list="products"
              option-value="id"
              option-text="title"
              :placeholder="$t('nothing_selected')"
              @searchchange="searchProduct(product.group_id, $event)"
              @input="setSelectedProduct(product.product_id, product.id)"
              :is-disabled="!product.can_change"
            ></model-list-select>
          </div>
          <div class="form-group" v-else>
            <label for="product_id">{{ $t("product") }}</label>
            <select v-model="product.product_id" class="form-control" :disabled="!product.can_change">
              <option :value="product.product_id">{{ product.product }}</option>
            </select>
          </div>
          <div class="form-group">
            <h6>
              {{ $t('selected') }}:
              <span v-if="product.product" class="text-danger">{{ product.product }}</span>
              <span v-else class="text-danger">{{ product.title }}</span>
            </h6>
          </div>

          <div v-if="(product.characteristics || []).length > 0">
            <div class="form-group" v-if="product.product_id">
              <label for="characteristic_id">{{ $t("characteristics") }}</label>
              <select
                v-model="product.characteristic_id"
                class="form-control"
                @change="changeCharasteristic(product.id, $event)"
                :disabled="!product.can_change"
              >
                <option
                  v-for="item in product.characteristics"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <price
              v-if="product.characteristic_id"
              :order="orderData"
              :product="product"
              :characteristic-id="product.characteristic_id"
              :key="product.characteristic_id"
            />
            <ul v-if="product.characteristic_id" class="list-unstyled">
              <li>
                <strong>{{ $t("in_stock") }}:</strong>
                {{ getQtyByCs(product.characteristic_id).totalQty }}
              </li>
              <li>
                <strong>{{ $t("production_is_possible") }}:</strong>
                {{ getQtyByCs(product.characteristic_id).productionQty }}
              </li>
            </ul>
          </div>
          <div v-else>
            <div>
              <span v-if="product.price_dropshipper > 0">
                {{ $t('product_price') }}: {{ product.price_dropshipper | currency }} /шт.
              </span>
              <!--<span v-else>
                {{ $t('price') }}: {{ product.price | currency }} /шт.
              </span>-->
              <br>
              {{ $t('price_dropshipper') }}: {{ product.price_drop | currency }} /шт.
            </div>
            <price :product="product" :key="product.id" />
            <ul class="list-unstyled">
              <li>
                <strong>{{ $t("in_stock") }}:</strong>
                {{ product.total_quantity }}
              </li>
              <li>
                <strong>{{ $t("production_is_possible") }}:</strong>
                {{ product.production_quantity }}
              </li>
            </ul>
          </div>
        </b-col>
        <!-- product stages -->
        <stages v-if="product && product.stages" :product-id="product.id" />
        <b-col v-else sm="8">
          <h5 class="text-center">{{ $t("empty") }}</h5>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="orderData.delivery.delivery_method" class="text-center mt-2">
      <b-button variant="secondary" size="sm" @click="addProduct" :disabled="!btnAddProductStatus"><fa icon="plus" /> {{ $t("add_product") }}</b-button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { ModelListSelect } from 'vue-search-select'
import { v4 as uuidv4 } from 'uuid'
import Stages from '~/pages/orders/edit/stages'
import Price from '~/pages/orders/edit/price'
import PriceChar from '~/pages/orders/product/price'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    ModelListSelect,
    Stages,
    Price,
    PriceChar
  },
  data: () => ({
    groups: [],
    selectedProduct: '',
    products: [],
    selectedProducts: []
  }),
  computed: {
    ...mapGetters({
      orderData: 'order/order',
      deliveries: 'order/deliveries',
      editOn: 'order/editProductsData',
      printed: 'order/printed',
      btnAddProductStatus: 'order/btnAddProductStatus'
    })
  },
  mounted () {
    this.fetchGroup()
  },
  methods: {
    ...mapActions({
      updateTotalPrice: 'order/updateTotalPrice',
      setDeliveryData: 'order/setDeliveryData',
      editCheck: 'order/editCheck',
      updateOrder: 'order/updateOrder'
    }),
    checkEditable (param) {
      this.editCheck(param)
      if (this.printed) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    updateProducts () {
      const id = this.$route.params.id
      this.updateOrder({ id: id, type: 'EDIT_PRODUCTS' })
    },
    async fetchGroup () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'groups/list').then((resp) => {
        this.groups = this.listToTree(resp.data.data)
      })
    },
    async searchProduct (groupId, key) {
      if (key.length > 3) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios
          .post(apiUrl + 'products/search', { groupId: groupId, key: key })
          .then((resp) => {
            this.products = resp.data.data
          })
      }
    },
    setSelectedProduct (productId, uuid) {
      const product = this.products.find(p => p.id === productId)
      this.orderData.products.forEach(p => {
        if (p.id === uuid) {
          p.product_id = product.id
          p.title = product.title
          p.characteristics = []
          if (Object.keys(product.characteristics.data).length > 0) {
            p.characteristics = product.characteristics.data.map(c => {
              return {
                id: c.id,
                name: c.name,
                price: c.price,
                price_drop: c.price_drop,
                price_dropshipper: c.price_dropshipper,
                production_quantity: c.production_quantity,
                total_quantity: c.total_quantity,
                stages: c.stages.data.filter(s => {
                  if (s.visibility) {
                    return {
                      id: s.id,
                      name: s.name,
                      visibility: s.visibility
                    }
                  }
                })
              }
            })
            p.status = 'Новий'
          } else {
            p.stages = []
          }
          p.quantity = 1
          p.price = product.price
          p.price_drop = product.price_drop
          p.price_dropshipper = product.price_dropshipper
          p.total_price = 0
          p.total_price_drop = 0
          p.total_quantity = Number.parseInt(product.totalQuantity)
          p.production_quantity = Number.parseInt(product.productionQuantity)
        }
      })
      if (this.orderData.delivery.delivery_method === 'nova-posta') {
        this.setDeliveryPrice()
      }
      this.updateTotalPrice()
    },
    changeCharasteristic (uuid, e) {
      const characteristicId = e.target.value
      if (characteristicId.length > 0) {
        // eslint-disable-next-line prefer-const
        let product = this.orderData.products.find((p) => p.id === uuid)
        if (product.characteristics) {
          const charactr = product.characteristics.find((c) => c.id === characteristicId)
          if (charactr.stages) {
            product.stages = charactr.stages.map(s => {
              return {
                id: s.id,
                name: s.name,
                stage_id: s.id
              }
            })
          } else {
            product.stages = []
          }
          product.stagesSelected = []
          product.stage_id = null
          product.total_price = 0
          product.total_price_drop = 0
        }
      }
      this.updateTotalPrice()
    },
    addProduct () {
      this.orderData.products.push({
        id: uuidv4(),
        group_id: '',
        new: true,
        can_change: true
      })
    },
    removeProduct (id) {
      const product = this.orderData.products.findIndex((p) => p.id === id)
      this.orderData.products.splice(product, 1)
      this.updateTotalPrice()
    },
    getQtyByCs (id) {
      let data = { totalQty: 0, productionQty: 0 }
        this.orderData.products.forEach(p => {
          if ((p.characteristics || []).length > 0) {
            const characteristic = p.characteristics.find(c => c.id === id)
            if (typeof characteristic !== 'undefined') {
              data = {
                totalQty: characteristic.total_quantity,
                productionQty: characteristic.production_quantity
              }
            }
          }
        })
      return data
    },
    async setDeliveryPrice () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const products = this.orderData.products.map(p => {
        return p.id
      })
      if (this.orderData.delivery.delivery_method === 'nova-posta') {
        const citySender = this.deliveries.find(d => d.slug === this.orderData.delivery.delivery_method)
        const { data } = await axios.post(apiUrl + 'deliveries/price', {
          products: products,
          citySender: citySender.city_ref,
          cityRecipient: this.orderData.delivery.city_recipient,
          deliveryType: this.orderData.delivery.delivery_type,
          deliveryMethod: this.orderData.delivery.delivery_method
        })
        this.setDeliveryData(data)
        this.$root.$refs.loading.finish()
      }
    },
    listToTree (list) {
      const map = {}
      let node
      const roots = []
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i
        list[i].children = []
      }

      for (let i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.parent_id > 0) {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.parent_id]].children.push(node)
        } else {
          roots.push(node)
        }
      }
      return roots
    }
  }
}
</script>
